/** 
 * !!! DO NOT MODIFY !!!
 * 
 * File generated from types definition in project https://forge.cadoles.com/EFS/sheila.
 *
 * See misc/script/generate-type-defs.sh script for more informations.
 **/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toSpecificitiesKey = exports.toHLAResultRef = void 0;
function toHLAResultRef(hlaResult) {
    return {
        batchDate: hlaResult.batchDate,
        class: hlaResult.class,
        catalogId: hlaResult.catalogId,
        origin: hlaResult.origin,
        sample: hlaResult.sample
    };
}
exports.toHLAResultRef = toHLAResultRef;
function toSpecificitiesKey(specificities) {
    return specificities.filter(function (s) { return !!s; }).join('-');
}
exports.toSpecificitiesKey = toSpecificitiesKey;
