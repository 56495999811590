
import { Donor } from '../types/Crossmatch'
import { WorkspaceCollectedData, WorkspaceHLAResult, WorkspaceHLAResults } from '../types/Sample'
import { isNullish } from './data'
import { ClassName } from '../types/shared/v1/ClassName'
import { TypingGroup } from '../types/shared/v1/PatientTyping'
import { TypingType } from '../types/shared/v1/PatientTyping'
import { ConsolidatedPatientV2 } from '../types/shared/v2/Patient'
import { parseFloor } from './math'
import { AnalysisResultGroup, AnalysisResultGroupType } from '../types/shared/v2/AnalysisResultGroup'
import { findBestMatchingGroup, matchThresholds } from './analysis-request'
import { StoreObject } from './objects'
import { HLAResultDecision } from '../types/shared/v2/HLAResultDecision'
import { HLAResult, HLAResultRef, toSpecificitiesKey } from '../types/shared/v2/HLAResult'
import { HLAEngineResult } from '../types/shared/v2/HLAEngineResult'
import { HLAEngineInterpretation } from '../types/shared/v2/HLAEngineResult'
import { Config } from '../config'


export function flattenWorkspaceResults(results: WorkspaceHLAResults): { [key: string]: StoreObject<WorkspaceHLAResult> } {
  const workspace: { [key: string]: StoreObject<WorkspaceHLAResult> } = {}

  Object.keys(results.byClass).forEach(className => {
    Object.keys(results.byClass[className]).forEach(key => {
      workspace[`${className}-${key}`] = results.byClass[className][key]
    })
  })

  return workspace
}

export function getMaxMFI(result: WorkspaceHLAResult): any {
  let ordered = [...result.data]
  ordered = ordered.sort((a: WorkspaceCollectedData, b: WorkspaceCollectedData) => (a.normalValue !== null && b.normalValue !== null) ? parseFloor(b.normalValue) - parseFloor(a.normalValue) : -1)

  return parseFloor(ordered[0].normalValue)
}

export interface DailyPosStrictSpecAbbrs {
  date: string
  results: string[]
}

// TODO Utiliser les codes d'examens et les groupes de résultat dynamiques pour récupérer
// les données à injecter dans la synthèse Cristal
export function getDailyResStrictPositive(set: WorkspaceHLAResult, resultGroups: AnalysisResultGroup[]): DailyPosStrictSpecAbbrs {
  const dailyRes: DailyPosStrictSpecAbbrs = {
    date: set.batchDate,
    results: []
  }

  // for (let h = 0; h < set.data.length; h++) {
  //   const value = parseFloor(set.data[h].normalValue)
  //   if (value > thresholds[thresholds.length - 2].valueMin) {
  //     dailyRes.results.push(set.data[h].specAbBr)
  //   }
  // }

  return dailyRes
}

// TODO Utiliser les codes d'examens et les groupes de résultat dynamiques pour récupérer
// les données à injecter dans la synthèse Cristal
export function getDailyResByDescThresholds(result: WorkspaceHLAResult, resultGroups: AnalysisResultGroup[]): any {
  // const thresholdIdToInlogId: any = [
  //   'SAI_AN1',
  //   'SAI_AN2',
  //   'SAI_AN4',
  //   'SAI_AN6',
  //   'SAI_AN8'
  // ]

  const dailyRes: any = {
    SAI_AN8: [],
    SAI_AN6: [],
    SAI_AN4: [],
    SAI_AN2: [],
    SAI_AN1: []
  }

  // for (let h = 0; h < result.data.length; h++) {
  //   for (let i = 0; i < thresholds.length; i++) {
  //     const value = parseFloor(result.data[h].normalValue)
  //     switch (thresholds[i].type) {
  //       case 'inferior':
  //         if (value < thresholds[i].valueMin) {
  //           dailyRes[thresholdIdToInlogId[i]].push(result.data[h].specAbBr)
  //         }
  //         break
  //       case 'between':
  //         if (value >= thresholds[i].valueMin && value <= thresholds[i].valueMax) {
  //           dailyRes[thresholdIdToInlogId[i]].push(result.data[h].specAbBr)
  //         }
  //         break
  //       case 'superior':
  //         if (value > thresholds[i].valueMax) {
  //           dailyRes[thresholdIdToInlogId[i]].push(result.data[h].specAbBr)
  //         }
  //         break
  //     }
  //   }
  // }

  return dailyRes
}

export type Interpretations = {
  [key in ClassName]: {
    [beadId: string]: InterpretationBead
  }
}

export interface InterpretationBead {
  allelic: string,
  generic: string;
  visible: boolean | undefined;
  synthesis?: StoreObject<AnalysisResultGroup>;
}

export function getDefaultInterpretations(hlaResults: WorkspaceHLAResults, resultGroups: StoreObject<AnalysisResultGroup>[], hlaEngineResult?: StoreObject<HLAEngineResult>): Interpretations {
  const interpretations: Interpretations = {
    [ClassName.CLASS1]: {},
    [ClassName.CLASS2]: {}
  }

  const prevailing: {
    [className in ClassName]: {
      [specAbbr: string]: StoreObject<AnalysisResultGroup>
    }
  } = {
    [ClassName.CLASS1]: {},
    [ClassName.CLASS2]: {}
  }

  Object.keys(hlaResults.byClass).forEach((className: ClassName) => {
    Object.values(hlaResults.byClass[className]).forEach((result: StoreObject<WorkspaceHLAResult>) => {
      if (result.data.disabled) {
        return
      }

      result.data.data.forEach((result) => {
        const specificitiesKey = toSpecificitiesKey(result.specificities)
        if (!specificitiesKey) return

        let best: StoreObject<AnalysisResultGroup> | undefined;

        if (isNullish(interpretations[className][specificitiesKey])) {
          interpretations[className][specificitiesKey] = {
            allelic: specificitiesKey,
            generic: result.specAbBr,
            visible: result.visible
          }
        }

        // On applique l'algorithme de niveau 2 (moteur de règles) en priorité
        if (hlaEngineResult) {
          const seroInterpretation = hlaEngineResult.data.sero[result.specAbBr]?.interpretation;
          const beadInterpretation = hlaEngineResult.data.beads[specificitiesKey]?.interpretation;

          if (beadInterpretation && beadInterpretation !== HLAEngineInterpretation.Unknown) {
            best = resultGroups.find(group => group.data.type === AnalysisResultGroupType.Historized && group.data.code === Config.hlaRuleEngineInterpretationMappings[beadInterpretation])
          } else if (seroInterpretation && seroInterpretation !== HLAEngineInterpretation.Unknown) {
            best = resultGroups.find(group => group.data.type === AnalysisResultGroupType.Historized && group.data.code === Config.hlaRuleEngineInterpretationMappings[seroInterpretation])
          }
        }

        // Si l'algorithme de niveau 2 n'a pas fourni d'interprétation, alors on utilise l'interprétation de niveau 1
        if (best === undefined) {
          const value = parseFloor(result.normalValue)
          const historizedMatches = resultGroups.filter(group => group.data.type === AnalysisResultGroupType.Historized && matchThresholds(group.data.thresholds, value));
          best = findBestMatchingGroup(historizedMatches, value)
        }

        if (best) {
          const prevail = prevailing[className][specificitiesKey];
          if (!prevail) {
            prevailing[className][specificitiesKey] = best;
          } else {
            if (best.data.weight > prevail.data.weight) {
              prevailing[className][specificitiesKey] = best
            }
          }
        }

        interpretations[className][specificitiesKey].synthesis = best
      })
    })
  })

  // On refait une passe pour remplacer les entrées 
  Object.keys(interpretations).forEach((className: ClassName) => {
    Object.keys(interpretations[className]).forEach((key: string) => {
      const prevail = prevailing[className][interpretations[className][key].allelic]
      if (!prevail) return;
      interpretations[className][key].synthesis = prevail
    })
  })


  return interpretations
}

export function getHistorisedSynthesis(interpretations: any): any {
  const thresholdIdToInlogId: any = {
    forbidden: 'AC1INT',
    neither: 'AC1ZG',
    allowed: 'AG1PER'
  }

  const thresholdIdToConcatInlogId: any = {
    neither: 'ACTZG',
    allowed: 'AGTPER'
  }

  const synthesis: any = {
    AC1INT: [],
    AC1ZG: [],
    ACTZG: [],
    AG1PER: [],
    AGTPER: []
  }

  const finalInterpretations: any = {}

  Object.keys(interpretations).forEach((className: any) => {
    Object.keys(interpretations[className]).forEach((key: any) => {
      if (isNullish(finalInterpretations[interpretations[className][key].generic])) {
        finalInterpretations[interpretations[className][key].generic] = interpretations[className][key].synthesis
      }

      if (!isNullish(finalInterpretations[interpretations[className][key].generic]) && finalInterpretations[interpretations[className][key].generic] !== interpretations[className][key].synthesis) {
        if (finalInterpretations[interpretations[className][key].generic] === 'allowed') {
          finalInterpretations[interpretations[className][key].generic] = interpretations[className][key].synthesis
        }

        if (finalInterpretations[interpretations[className][key].generic] === 'neither' && interpretations[className][key].synthesis === 'forbidden') {
          finalInterpretations[interpretations[className][key].generic] = interpretations[className][key].synthesis
        }
      }
    })
  })

  Object.keys(finalInterpretations).forEach((key: any) => {
    if (synthesis[thresholdIdToInlogId[finalInterpretations[key]]].includes(key) === false) {
      synthesis[thresholdIdToInlogId[finalInterpretations[key]]].push(key)
    }

    if (!isNullish(thresholdIdToConcatInlogId[finalInterpretations[key]]) && synthesis[thresholdIdToConcatInlogId[finalInterpretations[key]]].includes(key) === false) {
      synthesis[thresholdIdToConcatInlogId[finalInterpretations[key]]].push(key)
    }
  })

  return synthesis
}

export function getCrossmatchData(patient: ConsolidatedPatientV2, hlaResults: WorkspaceHLAResults, donor: Donor): any {
  const crossmatch = {}
  const flatDonorTyping: string[] = []
  const flatPatientTyping: string[] = []

  Object.keys(donor.typings).forEach((key) => {
    Object.values(donor.typings[key]).forEach((typing: string) => {
      flatDonorTyping.push(String(key + '*' + typing))
    })
  })

  Object.values(TypingGroup).forEach((group: string) => {
    Object.keys(patient.flatTypings[TypingType.TYPAGE_BM]).forEach((tKey) => {
      if (String(patient.flatTypings[TypingType.TYPAGE_BM][tKey]).startsWith(group)) {
        flatPatientTyping.push(String(patient.flatTypings[TypingType.TYPAGE_BM][tKey]))
      }
    })
  })

  flatDonorTyping.forEach((typing: string, index: number) => {
    let fill: boolean = false

    // Si la spé se termine par un tiret, elle est égale à la spé précédente
    const correctTyping = typing.endsWith('*') ? flatDonorTyping[index - 1] : typing

    // Si la spé est vide dans le typage du patient, on fait sortir la spé dans le tableau
    if (isNullish(flatPatientTyping[index])) {
      crossmatch[correctTyping] = {
        maxMFI: 0,
        date: new Date()
      }
      return
    }

    // On applique le même traitement pour les autres spé comparées que pour la spé du donneur
    const currentPatientTyping = flatPatientTyping[index].endsWith('*') ? flatPatientTyping[index - 1] : flatPatientTyping[index]

    // si la spé est différente de la spé à la même position dans le typage patient, on fait sortir la spé dans le tableau
    if (correctTyping !== currentPatientTyping) {
      fill = true
    }

    if (index % 2 === 0 && index < flatDonorTyping.length - 1) {
      const nextPatientTyping = flatPatientTyping[index + 1].endsWith('*') ? flatPatientTyping[index] : flatPatientTyping[index + 1]
      // si la spé est la première du locus, on la compare aussi à la 2ème du locus en face et si différente, on fait sortir la spé dans le tableau
      if (correctTyping !== nextPatientTyping) {
        fill = true
      }
    } else {
      const previousPatientTyping = flatPatientTyping[index - 1].endsWith('*') ? flatPatientTyping[index - 2] : flatPatientTyping[index - 1]
      // si la spé est la deuxième du locus, on la compare aussi à la 1ère du locus en face et si différente, on fait sortir la spé dans le tableau
      if (correctTyping !== previousPatientTyping) {
        fill = true
      }
    }

    if (fill) {
      crossmatch[correctTyping] = {
        maxMFI: 0,
        date: new Date()
      }
    }
  })

  Object.values(hlaResults.byClass[ClassName.CLASS1]).forEach((result: StoreObject<WorkspaceHLAResult>) => {
    result.data.data.forEach((d: WorkspaceCollectedData) => {
      const specificitiesKey = toSpecificitiesKey(d.specificities)
      if (Object.keys(crossmatch).includes(specificitiesKey)) {
        const value = parseFloor(d.normalValue)
        crossmatch[specificitiesKey].maxMFI = isNullish(crossmatch[specificitiesKey].maxMFI) ? value : Math.max(crossmatch[specificitiesKey].maxMFI, value ?? 0)
        if (crossmatch[specificitiesKey].maxMFI === value) {
          crossmatch[specificitiesKey].date = result.data.ref.analysisRequest.requestDate
        }
      }
    })
  })

  return crossmatch
}


export function matchHLAResultRef(hlaResult: StoreObject<HLAResult>, ref: HLAResultRef) {
  return ref.batchDate === hlaResult.data.batchDate
    && ref.catalogId === hlaResult.data.catalogId
    && ref.class === hlaResult.data.class
    && ref.origin === hlaResult.data.origin
    && ref.sample === hlaResult.data.sample
}