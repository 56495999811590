/** 
 * !!! DO NOT MODIFY !!!
 * 
 * File generated from types definition in project https://forge.cadoles.com/EFS/sheila.
 *
 * See misc/script/generate-type-defs.sh script for more informations.
 **/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HLAEngineInterpretation = void 0;
var HLAEngineInterpretation;
(function (HLAEngineInterpretation) {
    HLAEngineInterpretation["Unknown"] = "unknown";
    HLAEngineInterpretation["Allowed"] = "allowed";
    HLAEngineInterpretation["Forbidden"] = "forbidden";
    HLAEngineInterpretation["GreyZone"] = "greyZone";
})(HLAEngineInterpretation || (exports.HLAEngineInterpretation = HLAEngineInterpretation = {}));
